@import "~slick-carousel/slick/slick.scss";
@import "~@fancyapps/fancybox/dist/jquery.fancybox.css";

html {
    font-size: 15px;
}
body {
    font-size: 16px;
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}
.loader {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 10px solid rgba(#047857, 0.1);
    border-right: 10px solid rgba(#047857, 0.1);
    border-bottom: 10px solid rgba(#047857, 0.1);
    border-left: 10px solid #047857;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.grecaptcha-badge {
    display: none !important;
}
